<template>
  <div id="app">
    <div class="se-pre-con"></div>
    <Header></Header>
    <div>
      <router-view />

      <div></div>
    </div>
    <Footer> </Footer>
  </div>
</template>


<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import $ from "jquery";

export default {
  components: {
    Header,
    Footer,
  },
  mounted() {
    // $(window).on('load',function() {
    //   $(".lds-ripple").fadeOut(1000);
    //   $(".content").fadeIn(1000);
    // });

    $(window).on("load", function () {
      // Animate loader off screen
      $(".se-pre-con").fadeOut("slow");
    });
  },
};
</script>
 <style lang="scss">
#app {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.2;
  width: auto;
}
.no-js #loader {
  display: none;
}
.js #loader {
  display: block;
  position: absolute;
  left: 100px;
  top: 0;
}
.se-pre-con {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(https://nocbbo.com/images/loader6.gif) center no-repeat white;
}
</style>

 