<template>
<div class=" light">
  <div class="container pb-5 ">
    <section class="wrapper">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="card text-white card-has-bg click-col mt-5"
              style="
                background-image: url('https://nocbbo.com/images/card3.jpg');
              "
            >
              <img
                class="card-img d-none"
                src="https://nocbbo.com/images/card1.jpg"
                alt="Goverment Lorem Ipsum Sit Amet Consectetur dipisi?"
              />
              <div class="card-img-overlay d-flex flex-column">
                <div class="card-body">
                  <small class="card-meta mb-2">Storage and Loading</small>
                  <h4 class="card-title mt-2">
                    <a class="text-white shadow" herf="#">OUR TERMINAL</a>
                  </h4>
                  <p class="card-space-text mb-2" herf="#">
                    <font-awesome-icon
                      icon="chevron-right"
                      style="font-size: 0.8em"
                    />
                    Inter terminal and inter tank transfers
                  </p>
                  <p class="card-space-text text-left mb-2" herf="#">
                    <font-awesome-icon
                      icon="chevron-right"
                      style="font-size: 0.8em"
                    />
                    High speed centrifugal and PD pumps, tank/pipeline stripping
                    pumps.
                  </p>

                  <p class="card-space-text text-left" herf="#">
                    <font-awesome-icon
                      icon="chevron-right"
                      style="font-size: 0.8em"
                    />
                    Blending, storage and supply of Fuel oil bunkers for IMO
                    2020, Crude oil, and clean products.
                  </p>

                </div>
                    <div class="card-footer">
                  <div class="media">
                    <b-button
                      size="sm"
                      type="submit"
                      variant="outline-light"
                      href="#"
                      ><b style="font-weight: 500; font-size: 12px"
                        >Read More
                      </b>
                      <font-awesome-icon icon="arrow-right" />
                    </b-button>
                    <div class="media-body"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="card text-white card-has-bg click-col mt-5"
              style="
                background-image: url('https://nocbbo.com/images/card2.jpg');
              "
            >
              <img
                class="card-img d-none"
                src="https://nocbbo.com/images/card3.jpg"
                alt="Goverment Lorem Ipsum Sit Amet Consectetur dipisi?"
              />
              <div class="card-img-overlay d-flex flex-column">
                <div class="card-body">
                  <small class="card-meta mb-2">
                    Health, Safety, Security, and Environment</small
                  >
                  <h4 class="card-title mt-2">
                    <a class="text-white shadow" herf="#">QHSSE POLICY</a>
                  </h4>

                  <p class="card-space-text mb-2" herf="#">
                    <font-awesome-icon
                      icon="chevron-right"
                      style="font-size: 0.8em"
                    />
                    Focus on customer satisfaction.
                  </p>
                  <p class="card-space-text" herf="#">
                    <font-awesome-icon
                      icon="chevron-right"
                      style="font-size: 0.8em"
                    />
                    Prevent pollution and implement resource conservation as
                    critia management considerations. Establish clear quality,
                    health, safery and environmental objectives and targets.
                  </p>
                </div>
                <div class="card-footer">
                  <div class="media">
                    <b-button
                      size="sm"
                      type="submit"
                      variant="outline-light"
                      href="#"
                      ><b style="font-weight: 500; font-size: 12px"
                        >Read More
                      </b>
                      <font-awesome-icon icon="arrow-right" />
                    </b-button>
                    <div class="media-body"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="card text-white card-has-bg click-col mt-5"
              style="
                background-image: url('https://nocbbo.com/images/card1.jpg');
              "
            >
              <img
                class="card-img d-none"
                src="https://nocbbo.com/images/card2.jpg"
                alt="Goverment Lorem Ipsum Sit Amet Consectetur dipisi?"
              />
              <div class="card-img-overlay d-flex flex-column">
                <div class="card-body">
                  <small class="card-meta mb-2">Ethics and Consistency</small>
                  <h4 class="card-title mt-2">
                    <a class="text-white shadow" herf="#">OUR VALUES</a>
                  </h4>
                  <p class="card-space-text mb-2" herf="#">
                    <font-awesome-icon
                      icon="chevron-right"
                      style="font-size: 0.8em"
                    />
                    Providing state of the art terminal facilities through
                    continual development, diversification and synergizing
                    business processes.
                  </p>
                  <p class="card-space-text mt-0" herf="#">
                    <font-awesome-icon
                      icon="chevron-right"
                      style="font-size: 0.8em"
                    />
                    Oil and Gas handling, storage and distribution services.
                  </p>
                </div>

                <div class="card-footer">
                  <div class="media">
                    <b-button
                      size="sm"
                      type="submit"
                      variant="outline-light"
                      href="#"
                      ><b style="font-weight: 500; font-size: 12px"
                        >Read More
                      </b>
                      <font-awesome-icon icon="arrow-right" />
                    </b-button>
                    <div class="media-body"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>  </div>
</template>

<script>
export default {
  name: "homecards",
};
</script>

<style lang="scss" scoped>
body {
  background-color: #fcbf49;
}

//post card styles

.card {
  border: none;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  overflow: hidden;
  border-radius: 20px;
  min-height: 350px;
box-shadow: 0 5px 21px -12px rgba(0, 0, 0, 0.66);

  @media (max-width: 768px) {
    min-height: 350px;
  }

  @media (max-width: 420px) {
    min-height: 350px;
  }

  &.card-has-bg {
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    background-size: 120%;
    background-repeat: no-repeat;
    background-position: center center;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: inherit;
      -webkit-filter: grayscale(1);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
    }

    &:hover {
      transform: scale(0.98);
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.3);
      background-size: 130%;
      transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);

      .card-img-overlay {
        transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
        background: rgb(35, 79, 109);
        background: linear-gradient(
          0deg,
          rgba(4, 69, 114, 0.5) 0%,
          rgba(4, 69, 114, 1) 100%
        );
      }
    }
  }
  .card-footer {
    background: none;
    border-top: none;
    .media {
      img {
        border: solid 3px rgba(234, 95, 0, 0.3);
      }
    }
  }
  .card-meta {
    color: orange;
  }
  .card-body {
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  &:hover {
    .card-body {
      margin-top: 15px;
      transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    }
    cursor: pointer;
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  .card-img-overlay {
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    background: rgb(35, 79, 109);
    background: linear-gradient(
      0deg,
      rgba(35, 79, 109, 0.3785889355742297) 0%,
      rgba(69, 95, 113, 1) 100%
    );
  }
}

@media (max-width: 768px) {
  .wrapper {
    margin-top: 100px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .wrapper {
    margin-top: 100px;
  }
}
.card-space-text {
  line-height: 20px;
  color: #e6e6e6;
}
.light{
   background-color: #f3f5f7;
}
</style>