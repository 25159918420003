<template>
  <div>
    <nav
      class="
        navbar navbar-expand-xl navbar-dark
        bg-transparent
        fixed-top
        nav-mobile-custom
      "
      :class="{ onScroll: !view.topOfPage }"
    >
      <b-container class="pr-0 pl-0">
        <router-link to="#" style="text-decoration: none">
          <a class="navbar-brand" 
            ><img class="logo" alt="logo" src="../assets/logo1.png" /></a
        ></router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <b-container class="pr-0 pl-0">
            <b-row class="ml-auto">
              <ul class="navbar-nav ml-auto pr-3">
                <li class="nav-item ml-auto pr-0 0">
                  <router-link to="/contactus" class="text-decoration-none">
                    <a class="nav-link fonta" href="#"
                      >CONTACT US</a
                    ></router-link
                  >
                </li>
                <li class="nav-item ml-auto pr-0">
                  <a class="nav-link fonta" href="#"> FR</a>
                </li>
                <li class="nav-item ml-auto pr-0 mr-0">
                  <a class="nav-link fonta" href="#"> EN</a>
                </li>
              </ul>
            </b-row>
            <div class="w-100"></div>
            <b-row class="ml-auto">
              <ul class="navbar-nav ml-auto pr-3">
                <li class="nav-item ml-auto p-auto">
                  <router-link to="/" style="text-decoration: none">
                    <a class="nav-link ml-auto ml-mobile" href="#" id="homeid">
                      <span class="ml-auto fonta pl-1"
                        ><font-awesome-icon icon="home" /> HOME</span
                      >
                    </a></router-link
                  >
                </li>

                <li class="nav-item dropdown ml-auto">
                  <a
                    class="nav-link dropdown-toggle ml-auto ml-mobile"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="ml-auto fonta pl-1"> ABOUT US</span>
                  </a>

                  <div
                    class="dropdown-menu ml-auto"
                    aria-labelledby="navbarDropdown"
                  >
                    <b-container
                      class="shadow ml-auto"
                      style="background-color: white !important"
                    >
                      <b-row class="">
                        <b-col md="4" class="myfix">
                          <p
                            class="pl-2 pt-4 pb-2 text-mega-custom  "
                          >
                            Berbera National Oil Company was founded on the
                            premise of operating some of the most technologically advanced bulk liquid storage
                            facilities in the world to ensure that all our
                            customers..
                            <br /><br /><b-button
                              size="sm"
                              type="submit"
                              variant="outline-light"
                              ><b style="font-weight: 500; font-size: 12px"
                                >READ MORE
                              </b>
                              <font-awesome-icon icon="arrow-right" />
                            </b-button>
                          </p>
                        </b-col>
                        <b-col md="4">
                          <p
                            class="pl-2 pt-4 text-justify mb-0"
                            style="font-weight: 500; font-size: 14px"
                          >
                            WHO WE ARE <br />
                          </p>
                          <div
                            class="w-100 mt-1 mb-3"
                            style="border-top: 2px solid #ffdab9"
                          ></div>
                          <ul class="nav">
                            <li class="nav-item dropdownitemcustom">
                              <router-link
                                to="/about"
                                style="text-decoration: none"
                              >
                                <a
                                  class="
                                    nav-link
                                    text-dark text-mega-custom1
                                    pt-0
                                    pb-1
                                  "
                                  ><font-awesome-icon
                                    icon="chevron-right"
                                    style="font-size: 0.8em"
                                  />
                                  About Us</a
                                ></router-link
                              >
                            </li>
                            <div class="w-100"></div>
                            <li class="nav-item dropdownitemcustom">
                                  <router-link
                                to="/contactus"
                                style="text-decoration: none"
                              >
                                <a
                                  class="
                                    nav-link
                                    text-dark text-mega-custom1
                                    pt-0
                                    pb-1
                                  "
                                  ><font-awesome-icon
                                    icon="chevron-right"
                                    style="font-size: 0.8em"
                                  />
                                  History</a
                                ></router-link
                              >
                            </li>
                            <div class="w-100"></div>
                            <li class="nav-item dropdownitemcustom">
                                  <router-link
                                to="/about"
                                style="text-decoration: none"
                              >
                                <a
                                  class="
                                    nav-link
                                    text-dark text-mega-custom1
                                    pt-0
                                    pb-1
                                  "
                                  ><font-awesome-icon
                                    icon="chevron-right"
                                    style="font-size: 0.8em"
                                  />
                                  Mission, Vision, and Values</a
                                ></router-link
                              >
                            </li>
                            <div class="w-100"></div>
                            <li class="nav-item dropdownitemcustom">
                                 <router-link
                                to="/contactus"
                                style="text-decoration: none"
                              >
                                <a
                                  class="
                                    nav-link
                                    text-dark text-mega-custom1
                                    pt-0
                                    pb-1
                                  "
                                  ><font-awesome-icon
                                    icon="chevron-right"
                                    style="font-size: 0.8em"
                                  />
                                  Director's Message</a
                                ></router-link
                              >
                            </li>
                            <div class="w-100"></div>
                            <li class="nav-item dropdownitemcustom mb-5">
                                    <router-link
                                to="/about"
                                style="text-decoration: none"
                              >
                                <a
                                  class="
                                    nav-link
                                    text-dark text-mega-custom1
                                    pt-0
                                    pb-1
                                  "
                                  ><font-awesome-icon
                                    icon="chevron-right"
                                    style="font-size: 0.8em"
                                  />
                                  Press Release</a
                                ></router-link
                              >
                            </li>
                          </ul>
                        </b-col>
                        <b-col md="4" class="border-left pb-3">
                          <p
                            class="pl-2 pt-4 text-justify mb-0"
                            style="font-weight: 500; font-size: 15px"
                          >
                            NOCBBO PROFILE <br />
                          </p>
                          <div
                            class="w-100 mt-1 mb-3"
                            style="border-top: 2px solid #ffdab9"
                          ></div>
                          <a href="">
                            <img
                              src="../assets/companyprofile3.jpg"
                              class="img-fluid"
                            />
                          </a>
                        </b-col>
                      </b-row>
                    </b-container>
                  </div>
                </li>

                <li class="nav-item dropdown ml-auto">
                  <a
                    class="nav-link dropdown-toggle ml-auto ml-mobile"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="ml-auto fonta pl-1"> OUR TERMINAL</span>
                  </a>

                  <div
                    class="dropdown-menu ml-auto"
                    aria-labelledby="navbarDropdown"
                  >
                    <b-container
                      class="shadow ml-auto"
                      style="background-color: white !important"
                    >
                      <b-row class="">
                        <b-col md="4" class="myfix">
                          <p
                            class="pl-2 pt-4 pb-2 text-mega-custom  "
                          >
                            We care about your storage needs and go the extra
                            mile to make sure you are satisfied with our
                            services. Our team works closely together to share
                            their knowledge, expertise and best practices. This
                            enables NOCBBO to respond rapidly and accurately as
                            per customer needs and market developments. <br />
                            <br /><b-button
                              size="sm"
                              type="submit"
                              variant="outline-light"
                              ><b style="font-weight: 500; font-size: 12px"
                                >READ MORE
                              </b>
                              <font-awesome-icon icon="arrow-right" />
                            </b-button>
                          </p>
                        </b-col>
                        <b-col md="4">
                          <p
                            class="pl-2 pt-4 text-justify mb-0"
                            style="font-weight: 500; font-size: 14px"
                          >
                            OUR TERMINAL <br />
                          </p>
                          <div
                            class="w-100 mt-1 mb-3"
                            style="border-top: 2px solid #ffdab9"
                          ></div>
                          <ul class="nav">
                            <li class="nav-item dropdownitemcustom">
                                  <router-link
                                to="/about"
                                style="text-decoration: none"
                              >
                                <a
                                  class="
                                    nav-link
                                    text-dark text-mega-custom1
                                    pt-0
                                    pb-1
                                  "
                                  ><font-awesome-icon
                                    icon="chevron-right"
                                    style="font-size: 0.8em"
                                  />
                                  Our Service</a
                                ></router-link
                              >
                            </li>
                            <div class="w-100"></div>
                            <li class="nav-item dropdownitemcustom">
                                     <router-link
                                to="/contactus"
                                style="text-decoration: none"
                              >
                                <a
                                  class="
                                    nav-link
                                    text-dark text-mega-custom1
                                    pt-0
                                    pb-1
                                  "
                                  ><font-awesome-icon
                                    icon="chevron-right"
                                    style="font-size: 0.8em"
                                  />
                                  How We Work</a
                                ></router-link
                              >
                            </li>
                            <div class="w-100"></div>
                            <li class="nav-item dropdownitemcustom">
                                     <router-link
                                to="/about"
                                style="text-decoration: none"
                              >
                                <a
                                  class="
                                    nav-link
                                    text-dark text-mega-custom1
                                    pt-0
                                    pb-1
                                  "
                                  ><font-awesome-icon
                                    icon="chevron-right"
                                    style="font-size: 0.8em"
                                  />
                                  Our Storage</a
                                ></router-link
                              >
                            </li>
                          </ul>
                        </b-col>
                        <b-col md="4" class="border-left pb-3">
                          <p
                            class="pl-2 pt-4 text-justify mb-0"
                            style="font-weight: 500; font-size: 15px"
                          >
                            NOCBBO PROFILE <br />
                          </p>
                          <div
                            class="w-100 mt-1 mb-3"
                            style="border-top: 2px solid #ffdab9"
                          ></div>
                          <a href="">
                            <img
                              src="../assets/companyprofile3.jpg"
                              class="img-fluid"
                            />
                          </a>
                        </b-col>
                      </b-row>
                    </b-container>
                  </div>
                </li>

                <li class="nav-item dropdown ml-auto">
                  <a
                    class="nav-link dropdown-toggle ml-auto ml-mobile"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="ml-auto fonta pl-1"> QHSSE</span>
                  </a>

                  <div
                    class="dropdown-menu ml-auto"
                    aria-labelledby="navbarDropdown"
                  >
                    <b-container
                      class="shadow ml-auto"
                      style="background-color: white !important"
                    >
                      <b-row class="">
                        <b-col md="4" class="myfix">
                          <p
                            class="pl-2 pt-4 pb-2 text-mega-custom "
                          >
                            It is the Policy of Berbera National Oil Company
                            (NOCBBO) that the highest priority si given to all
                            aspects of quality, health, safety and the
                            environment in all its business activities and that
                            the highest standards are implemented in all
                            Operating Divisions.
                            <br />
                            <br />
                            <b-button
                              size="sm"
                              type="submit"
                              variant="outline-light"
                              ><b style="font-weight: 500; font-size: 12px"
                                >READ MORE
                              </b>
                              <font-awesome-icon icon="arrow-right" />
                            </b-button>
                          </p>
                        </b-col>
                        <b-col md="4">
                          <p
                            class="pl-2 pt-4 text-justify mb-0"
                            style="font-weight: 500; font-size: 14px"
                          >
                            SAFETY AND QUALITY STANDARDS<br />
                          </p>
                          <div
                            class="w-100 mt-1 mb-3"
                            style="border-top: 2px solid #ffdab9"
                          ></div>
                          <ul class="nav">
                            <li class="nav-item dropdownitemcustom">
                              <a
                                class="
                                  nav-link
                                  text-dark text-mega-custom1
                                  pt-0
                                  pb-1
                                "
                                href="#"
                                ><font-awesome-icon
                                  icon="chevron-right"
                                  style="font-size: 0.8em"
                                />
                                Our Standards</a
                              >
                            </li>
                            <div class="w-100"></div>
                            <li class="nav-item dropdownitemcustom">
                              <a
                                class="
                                  nav-link
                                  text-dark text-mega-custom1
                                  pt-0
                                  pb-1
                                "
                                href="#"
                                ><font-awesome-icon
                                  icon="chevron-right"
                                  style="font-size: 0.8em"
                                />
                                HSSE Policy</a
                              >
                            </li>
                            <div class="w-100"></div>

                            <li class="nav-item dropdownitemcustom">
                              <a
                                class="
                                  nav-link
                                  text-dark text-mega-custom1
                                  pt-0
                                  pb-1
                                "
                                href="#"
                                ><font-awesome-icon
                                  icon="chevron-right"
                                  style="font-size: 0.8em"
                                />
                                Quality Policy</a
                              >
                            </li>
                            <div class="w-100"></div>

                            <li class="nav-item dropdownitemcustom">
                              <a
                                class="
                                  nav-link
                                  text-dark text-mega-custom1
                                  pt-0
                                  pb-1
                                "
                                href="#"
                                ><font-awesome-icon
                                  icon="chevron-right"
                                  style="font-size: 0.8em"
                                />
                                Alcohol and Drug Policy</a
                              >
                            </li>
                          </ul>
                        </b-col>
                        <b-col md="4" class="border-left pb-3">
                          <p
                            class="pl-2 pt-4 text-justify mb-0"
                            style="font-weight: 500; font-size: 15px"
                          >
                            NOCBBO PROFILE <br />
                          </p>
                          <div
                            class="w-100 mt-1 mb-3"
                            style="border-top: 2px solid #ffdab9"
                          ></div>
                          <a href="">
                            <img
                              src="../assets/companyprofile3.jpg"
                              class="img-fluid"
                            />
                          </a>
                        </b-col>
                      </b-row>
                    </b-container>
                  </div>
                </li>
                <li class="nav-item dropdown ml-auto">
                  <a
                    class="nav-link dropdown-toggle ml-auto ml-mobile"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="ml-auto fonta pl-1"> MEDIA CENTER</span>
                  </a>

                  <div
                    class="dropdown-menu ml-auto"
                    aria-labelledby="navbarDropdown"
                  >
                    <b-container
                      class="shadow ml-auto"
                      style="background-color: white !important"
                    >
                      <b-row class="">
                        <b-col md="4" class="myfix">
                          <p
                            class="pl-2 pt-4 pb-2 text-mega-custom  "
                          >
                            With its clear vision is paving its way with a
                            transparent strategy towards future profitable leaps
                            to optimize the satisfaction of their employees,
                            customers and suppliers by utilizing their knowledge
                            and vibrant team.Trust and commitment to quality has
                            played a major role in the company’s achieved
                            success which is mainly due to the threefold values:
                            <br />
                            <br />
                            <b-button
                              size="sm"
                              type="submit"
                              variant="outline-light"
                              ><b style="font-weight: 500; font-size: 12px">READ MORE
                              </b>
                              <font-awesome-icon icon="arrow-right" />
                            </b-button>
                          </p>
                        </b-col>
                        <b-col md="4">
                          <p
                            class="pl-2 pt-4 text-justify mb-0"
                            style="font-weight: 500; font-size: 14px"
                          >
                            MEDIA LIBRARY<br />
                          </p>
                          <div
                            class="w-100 mt-1 mb-3"
                            style="border-top: 2px solid #ffdab9"
                          ></div>
                          <ul class="nav">
                            <li class="nav-item dropdownitemcustom">
                              <a
                                class="
                                  nav-link
                                  text-dark text-mega-custom1
                                  pt-0
                                  pb-1
                                "
                                href="#"
                                ><font-awesome-icon
                                  icon="chevron-right"
                                  style="font-size: 0.8em"
                                />
                                Multimedia Gallery</a
                              >
                            </li>
                            <div class="w-100"></div>
                            <li class="nav-item dropdownitemcustom">
                              <a
                                class="
                                  nav-link
                                  text-dark text-mega-custom1
                                  pt-0
                                  pb-1
                                "
                                href="#"
                                ><font-awesome-icon
                                  icon="chevron-right"
                                  style="font-size: 0.8em"
                                />
                                Videos</a
                              >
                            </li>
                            <div class="w-100"></div>

                            <li class="nav-item dropdownitemcustom">
                              <a
                                class="
                                  nav-link
                                  text-dark text-mega-custom1
                                  pt-0
                                  pb-1
                                "
                                href="#"
                                ><font-awesome-icon
                                  icon="chevron-right"
                                  style="font-size: 0.8em"
                                />
                                Our Profile</a
                              >
                            </li>
                            <div class="w-100"></div>

                            <li class="nav-item dropdownitemcustom">
                              <a
                                class="
                                  nav-link
                                  text-dark text-mega-custom1
                                  pt-0
                                  pb-1
                                "
                                href="#"
                                ><font-awesome-icon
                                  icon="chevron-right"
                                  style="font-size: 0.8em"
                                />
                                Image Gallery</a
                              >
                            </li>
                          </ul>
                        </b-col>
                        <b-col md="4" class="border-left pb-3">
                          <p
                            class="pl-2 pt-4 text-justify mb-0"
                            style="font-weight: 500; font-size: 15px"
                          >
                            NOCBBO PROFILE <br />
                          </p>
                          <div
                            class="w-100 mt-1 mb-3"
                            style="border-top: 2px solid #ffdab9"
                          ></div>
                          <a href="">
                            <img
                              src="../assets/companyprofile3.jpg"
                              class="img-fluid"
                            />
                          </a>
                        </b-col>
                      </b-row>
                    </b-container>
                  </div>
                </li>
                <li class="nav-item ml-auto">
                  <router-link to="/careers" style="text-decoration: none">
                    <a
                      class="nav-link ml-auto fonta ml-mobile"
                      href="#"
                      style="margin-top: 1px"
                    >
                      CAREER</a
                    ></router-link
                  >
                </li>

                <li class="nav-item dropdown ml-auto">
                  <a
                    class="nav-link dropdown-toggle ml-auto ml-mobile"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="ml-auto fonta pl-1"> NEWS AND EVENTS</span>
                  </a>

                  <div
                    class="dropdown-menu ml-auto"
                    aria-labelledby="navbarDropdown"
                  >
                    <b-container
                      class="shadow ml-auto"
                      style="background-color: white !important"
                    >
                      <b-row>
                        <b-col md="4" class="myfix">
                          <p
                            class="pl-2 pt-4 pb-2 text-mega-custom  "
                          >
                            With its clear vision is paving its way with a
                            transparent strategy towards future profitable leaps
                            to optimize the satisfaction of their employees,
                            customers and suppliers by utilizing their knowledge
                            and vibrant team.Trust and commitment to quality has
                            played a major role in the company’s achieved
                            success which is mainly due to the threefold values:
                            <br />
                            <br />
                            <b-button
                              size="sm"
                              type="submit"
                              variant="outline-light"
                              ><b style="font-weight: 500; font-size: 12px"
                                >READ MORE
                              </b>
                              <font-awesome-icon icon="arrow-right" />
                            </b-button>
                          </p>
                        </b-col>
                        <b-col md="4">
                          <p
                            class="pl-2 pt-4 text-justify mb-0"
                            style="font-weight: 500; font-size: 14px"
                          >
                            NEWS AND EVENTS<br />
                          </p>
                          <div
                            class="w-100 mt-1 mb-3"
                            style="border-top: 2px solid #ffdab9"
                          ></div>
                          <ul class="nav">
                            <li class="nav-item dropdownitemcustom">
                              <a
                                class="
                                  nav-link
                                  text-dark text-mega-custom1
                                  pt-0
                                  pb-1
                                "
                                href="#"
                                ><font-awesome-icon
                                  icon="chevron-right"
                                  style="font-size: 0.8em"
                                />
                                Events</a
                              >
                            </li>
                            <div class="w-100"></div>
                            <li class="nav-item dropdownitemcustom">
                              <a
                                class="
                                  nav-link
                                  text-dark text-mega-custom1
                                  pt-0
                                  pb-1
                                "
                                href="#"
                                ><font-awesome-icon
                                  icon="chevron-right"
                                  style="font-size: 0.8em"
                                />
                                Press Release</a
                              >
                            </li>
                            <div class="w-100"></div>

                            <li class="nav-item dropdownitemcustom">
                              <a
                                class="
                                  nav-link
                                  text-dark text-mega-custom1
                                  pt-0
                                  pb-1
                                "
                                href="#"
                                ><font-awesome-icon
                                  icon="chevron-right"
                                  style="font-size: 0.8em"
                                />
                                Our Profile</a
                              >
                            </li>
                            <div class="w-100"></div>
                          </ul>
                        </b-col>
                        <b-col md="4" class="border-left pb-3">
                          <p
                            class="pl-2 pt-4 text-justify mb-0"
                            style="font-weight: 500; font-size: 15px"
                          >
                            NOCBBO PROFILE <br />
                          </p>
                          <div
                            class="w-100 mt-1 mb-3"
                            style="border-top: 2px solid #ffdab9"
                          ></div>
                          <a href="">
                            <img
                              src="../assets/companyprofile3.jpg"
                              class="img-fluid"
                            />
                          </a>
                        </b-col>
                      </b-row>
                    </b-container>
                  </div>
                </li>
              </ul>
            </b-row>
          </b-container>
        </div>
      </b-container>
    </nav>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "Header",
  components: {},
  data() {
    return {
      view: {
        topOfPage: true,
      },
    };
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 0) {
        if (this.view.topOfPage) this.view.topOfPage = false;
      } else {
        if (!this.view.topOfPage) this.view.topOfPage = true;
      }
    },
  },
  mounted() {
    $(document).ready(function () {
      $(window).resize(function () {
        if ($(window).width() >= 980) {
          $(".navbar .dropdown-toggle").hover(function () {
            $(this).parent().toggleClass("show");
            $(this).parent().find(".dropdown-menu").toggleClass("show");
          });

          $(".navbar .dropdown-menu").mouseleave(function () {
            $(this).removeClass("show");
          });
        }
      });
    });
  },
};
</script>

<style scoped lang="scss">
.onScroll {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #242424 !important;
  -webkit-transition: all 0.1s ease-in-out, background-position 1ms;
  -moz-transition: all 0.1s ease-in-out, background-position 1ms;
  transition: all 0.1s ease-in-out, background-position 1ms;

  .logo {
    -webkit-transition: all 0.4s ease-in-out, background-position 1ms;
    -moz-transition: all 0.4s ease-in-out, background-position 1ms;
    transition: all 0.4s ease-in-out, background-position 1ms;

    width: 97% !important;
  }
  .fonta {
    font-size: 95% !important;
    -webkit-transition: all 0.2s ease-in-out, background-position 1ms;
    -moz-transition: all 0.2s ease-in-out, background-position 1ms;
    transition: all 0.2s ease-in-out, background-position 1ms;
  }
}

.navbar .dropdown-menu div[class*="col"] {
  margin-bottom: 1rem;
}

.navbar .dropdown-menu {
  border: none;
  background-color: transparent !important;
}

@media screen and (min-width: 992px) {
  .navbar .dropdown {
    position: static;
  }

  .navbar .dropdown-menu {
    left: 0;
    right: 0;

    top: 70px;

    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
  }

  .navbar .dropdown:hover .dropdown-menu,
  .navbar .dropdown .dropdown-menu:hover {
    display: block;
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.3s linear;
  }
}
@media (max-width: 768px) {
  .nav-mobile-custom {
    background-color: rgb(36, 36, 36) !important;
  }
  .ml-mobile {
    float: right;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .navbar-collapse {
    background-color: rgb(36, 36, 36, 0.4) !important;
    padding: 5px;
  }
  .ml-mobile {
    float: right;
  }
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #f32114 !important;
  font-weight: 500;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #f32114 !important;
  font-weight: 500;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
  font-weight: 500;
}

.myfix {
  
  background-color: #db1305;
}
.text-mega-custom {
  line-height: 22px;
  font-size: 13px;
  font-weight: 400;
  color: rgb(230, 225, 225);
}
.text-mega-custom1 {
  line-height: 22px;
  font-size: 13px;
  font-weight: 400;
  color: rgb(230, 225, 225);
}
.navbar .dropdown-menu div[class*="col"]  {
  margin-bottom: 0 !important;
}
.dropdownitemcustom:hover {
  padding-left: 0.1rem;
}
</style>
