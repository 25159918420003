<template>
 
  <div class="w-100 d-none d-md-block " id="homeCarousel"  >

    <b-carousel
      id="carousel-fade"
    :interval="3000"
      fade
      controls
 
    >
      <b-carousel-slide   
         class="d-block img-fluid w-100"
   img-src="https://nocbbo.com/images/23.jpg"  
        
      >
   
      <b-container>
   <div class="carousel-extra-content w-100 d-block ">
       
             <b-row   >
           
                <h3 class=" font-slide-title" >  NATIONAL OIL COMPANY, BERBERA. </h3>
                        <div class="w-100"></div><h3 class=" font-slide-title-sub" >  Oil and Gas Terminal </h3>
                        <div class="w-100"></div>

                 <p class=" font-slide-title-p  " >Doing business in a clear, open and transparent way Is a <br>commitment we make to our partners, customers and suppliers </p>

                  <div class="w-100"></div>

                <b-button size="sm" class="mr-3" type="submit" variant="danger"
                 ><b style="font-weight: 500">Learn More </b>
                           <font-awesome-icon icon="arrow-right" />
                </b-button>
                <b-button size="sm" class="mr-3" type="submit" variant="light">
                  <b style="font-weight: 500">Our Profile </b>
                  <font-awesome-icon icon="download" />
                </b-button>    
           
            </b-row>
  
                
        </div>
        </b-container>
        </b-carousel-slide>
      <b-carousel-slide  
   img-src="https://nocbbo.com/images/24.jpg"
      >

      
         <b-container>
   <div class="carousel-extra-content w-100 d-block ">
       
             <b-row   >
           
                <h3 class=" font-slide-title" >  NATIONAL OIL COMPANY, BERBERA. </h3>
                        <div class="w-100"></div>
                        
                <h3 class=" font-slide-title-sub" >  Energy for Progress </h3>
                        <div class="w-100"></div>
                  <p class=" font-slide-title-p  " >We are rising to meet the energy needs of the fourth industrial age by embracing <br>technology, empowering people,  operating sustainable and  leveraging partnerships. </p>

                  <div class="w-100"></div>

                <b-button size="sm" class="mr-3" type="submit" variant="danger"
                 ><b style="font-weight: 500">See Gallery </b>
                           <font-awesome-icon icon="arrow-right" />
                </b-button>
                <b-button size="sm" class="mr-3" type="submit" variant="light">
                  <b style="font-weight: 500">Our Profile </b>
                  <font-awesome-icon icon="download" />
                </b-button>    
           
            </b-row>
  
                
        </div>
        </b-container>
      
      
      
      </b-carousel-slide>
  
    </b-carousel>
  </div>     
  
</template>

<script>
 
export default {
  name: "HomeCarousel",

 
};
</script>
<style>
.carousel-extra-content {
  position: absolute;
 
  top: 32%;
  z-index: 20; /* overlap arrow controls to allow clicks */
  color: rgb(255, 255, 255);
 
  font-family: "Montserrat", sans-serif;
   
 
 
 
}
.plight {
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 1.5;
}

.carousel-caption {
  top: 1.5em;
}
 
.font-slide-title{
  font-weight:600;
  font-family: "Montserrat", sans-serif;
 
  
   }
   .font-slide-title-sub{
  font-weight:600;
  font-family: "Montserrat", sans-serif;
  color:#c82333
 
  
   }
 
.font-slide-title-p{
 font-size: 14px;
  font-family: "Montserrat", sans-serif;
font-weight: 400;
line-height: 28px;
text-align: left
 

   }

 

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='pink' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='pink' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important;


}
#homeCarousel{
  width:100% !important;
}

 
 
</style>
