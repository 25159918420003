 <template>
  <div>

    <div id="home" class="w-100 ">
   
      <HomeCarousel />     
 
      <HomeCards /> 
      
        <HomeCards2 /> 
  
   
    </div>
  </div>
</template>

<script>
 
import HomeCarousel from "@/components/HomeCarousel.vue";
import HomeCards from "@/components/HomeCards.vue";
import HomeCards2 from "@/components/HomeCards2.vue";
 

export default {
  name: "Home",
  components: {
    HomeCarousel,
    HomeCards,
        HomeCards2,
        
  },

};
</script>

<style scoped lang="scss">
@media (max-width: 768px) {
  .p-custom {
    padding-top: 100px;
  }
}

</style>
