import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
   {
    path: "/contactus",
    name: "contactus",
      component: () =>
      import(/* webpackChunkName: "contactus" */ "../views/ContactUs.vue"),
  },
  {
    path: "/careers",
    name: "careers",
      component: () =>
      import(/* webpackChunkName: "careers" */ "../views/Careers.vue"),
  },
  {
    path: "/about",
    name: "About",
       component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
export default router;
